import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const TermsOfUse = props => (
  <Layout {...props}>
    <Seo title='Terms of Use' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Terms of Use' />
        <Divider />
        <p>
          These terms and conditions (collectively, “Terms”) apply to your use
          of the Football Today community, any related mobile device software
          applications, the Football today website at football-today.com, and
          content therein, and any other product or service that links to these
          Terms.
        </p>
        <h3>Accepting these Terms</h3>
        <p>
          Our offer of the Service, including all information, tools and
          services available from the Service to you is conditioned upon your
          acceptance of all the terms, conditions, policies and notices stated
          herein. Your use of the Service or any part thereof constitutes your
          agreement and is subject to these Terms and all applicable laws, rules
          and regulations. If you do not agree to these Terms you may not and
          should not use the Service or any part thereof.
        </p>
        <p>
          Certain of our Services may also be subject to additional specific
          terms, which will be linked to or otherwise incorporated into the
          applicable Services. Your use of those Services will be subject to
          those terms, and unless otherwise specified by us, these Terms as
          well. If there are any conflicts between the applicable Services’
          specific terms and these Terms, the specific Services’ terms will
          prevail, but only to the extent of the conflict.
        </p>
        <h3>Changes and Amendments to Terms</h3>
        <p>
          We reserve the right to amend, add or delete portions of these Terms
          at any time and at our sole discretion. While we may and may not post
          notices on the Service informing of changes in these Terms, you are
          solely responsible for reviewing these Terms periodically to ensure
          you are aware of any changes. The last revision of these Terms shall
          be reflected in the "Last modified" heading above. Your continuing use
          of the Service following any change or amendment to these Terms
          constitutes your acceptance of such change or update. If you do not
          agree or wish to be bound by these Terms as currently drafted and as
          may be amended from time to time, you should not use the Service or
          cease your use of the Service immediately. The Service may contain
          certain historical information, which is not current and is provided
          for your reference only.
        </p>
        <h3>Privacy Policy</h3>
        <p>
          We understand that you want to keep your information private, and that
          you value our emphasis on keeping your information safe and discrete.
          As a result we have drafted a privacy policy (the "Privacy Policy")
          which details the ways in which we collect, use and disclose personal
          information from users of the Service. The Privacy Policy is
          incorporated into, subject to and a part of the Service's Terms. Your
          use of the Service signifies your acknowledgement of, and agreement
          to, the Service’s Privacy Policy. If you do not agree to the Privacy
          Policy, you may not and should not use the Service.
        </p>
        <h3>Limitation on Use</h3>
        <p>
          You may not register or use the Service if you are under 16 years of
          age and/or if you are legally unable to enter into a legally binding
          agreement without the consent of your parents or legal guardian. The
          following uses of the Service or any part thereof are prohibited:
          Commercial use, unless specifically and explicitly allowed by us in a
          prior written consent; Damaging or infringing the rights of third
          parties in any way, including, without limitation, infringing on any
          third party's intellectual property or privacy rights; Illegal use,
          including without limitation fraud, pornography trafficking, drug
          dealing, sports betting and gambling; Distributing SPAM; Using users
          contact information for marketing purposes; Bullying, intimidating,
          stalking or harassing any person; Duplicating, downloading, copying,
          reproducing, framing, mirroring, modifying, transmitting, performing,
          broadcasting, rebroadcasting, publishing, selling, creating
          derivatives of, translating, distributing or redistributing the
          Service, the Service-related intellectual property, third parties User
          Content (defined below) or any part thereof or their products, unless
          specifically and explicitly allowed by us in a prior written consent;
          Altering, modifying, decompiling, disassembling, reverse engineering
          or otherwise attempting to discover or to learn information regarding
          the Service's (including its content's) source code and structure; Any
          automatic use which is intended or designed to gather information
          about or from the Service, including without limitation Bots,
          Crawlers, Spiders, Robots, Sifters and Load Testers; Uploading of any
          sort of malware, spyware or other malicious code; Circumventing or
          bypassing any measure designed to limit access to the Service or any
          part thereof; Damaging, disabling, impairing or flooding the Service;
          Competing with the Service.
        </p>
        <h3>Content Creator Terms</h3>
        <p>
          The Service may allow you to upload content to the Service directly in
          various forms, including without limitation statuses, links, images,
          comments, posts, articles and conversations (collectively referred to
          herein as “User Content”). You affirm, represent and warrant that you
          own all required rights, licenses, titles and interests in the User
          Content, necessary to upload it to the Service and to grant us the
          rights as stated herein, and that the User Content is accurate where
          it might be reasonably relied upon. You shall be held liable to any
          damages or losses incurred by us as a result of intellectual property
          infringement through the User Content, including payment of damages
          and statutory damages resulting from contributory or direct (meaning,
          even in the event that the content was edited and re-published by us)
          infringement. We reserve the right to remove any and all of the User
          Content if we reasonably determine that it is infringing upon any
          third party's rights, including, without limitation, intellectual
          property rights. We reserve the right to edit your content in any way
          we see fit and without any notice to you. We reserve the right to
          terminate your access to the Service, if we reasonably determine that
          you are an infringer. You acknowledge that you expect and shall
          receive no compensation, payment or interests as a result of uploading
          the User Content, other than agreed between us explicitly and in
          writing beforehand. You agree to allow any and all other users of the
          Service to access your User Content and to "follow" your profile
          freely and without compensation to you of any sort on their behalf.
          You hereby grant us a world-wide, unlimited, non-revocable,
          royalty-free, sub-licensable and transferable license to use the User
          Content, including, without limitation, the corresponding copyrighted
          works, trademarks, trade secrets, ideas, inventions, utility models,
          designs, patents, software and other intellectual property, in any way
          we see fit at our sole discretion, including without limitation
          reproduction, distribution, making of derivatives, modification,
          adaptation, publication, translation and display. In addition, you
          hereby explicitly waive any moral and attribution rights that you may
          have in and to the User Content and forever waive and agree not to
          claim or assert any entitlement to any and all moral or attribution
          rights in any of the User Content. For the avoidance of doubt, we may
          include your User Content in our outgoing feed to third parties (e.g.
          RSS feed), which may publish said feed (including your User Content)
          outside of the Service. In addition to abiding by these Terms, it is
          clarified that your User Content must also abide by Twitter,
          Instagram, YouTube and Facebook's Terms. You may not upload User
          Content which: May encourage acts of violence; Infringes third
          parties' intellectual property; Contains or constitutes SPAM or chain
          mail; Infringes third parties' right to privacy or publicity rights;
          Contains confidential or proprietary information; Is defamatory,
          fraudulent, libelous, abusive, obscene, unlawful, threatening,
          hateful, harassing, racial, sexist or otherwise inappropriate by
          reasonable standards; Advertise or solicit third parties' business or
          contains commercial content of any sort; Impersonate another person
          without proper notice; Contain any form of malware; Interfere with the
          Service in any way. We do not guarantee and we have no obligation to
          post, transmit, distribute, upload, publish or display any User
          Content and may refuse to do so at our sole discretion.
        </p>
        <p>
          Contributors who have specific agreements with us, may be subject to
          additional terms, rules and codes of conducts, which shall prevail
          over these Terms only in case of inconsistency.
        </p>
        <h3>General</h3>
        <p>
          You hereby agree and acknowledge that you will not be an employee,
          agent, or partner of or joint venturer with or of Minute Media, nor
          will you have any authority to bind Minute Media in any respect. We
          may assign our rights according to this Agreement to any third party
          at our sole discretion. The Sections: Intellectual Property Rights,
          Disclaimers, Indemnification, Governing Law; Jurisdiction and General,
          and any licenses granted to us herein, shall survive any termination
          or expiration of these Terms. You will be responsible for paying,
          withholding, filing, and reporting all taxes, duties, and other
          governmental assessments associated with your activity in connection
          with the Service, provided that we may, in our sole discretion, do any
          of the foregoing on your behalf or for ourselves if and as we see fit.
          You may not assign your rights according to this Agreement, without
          our prior written consent. If any part of these Terms is deemed
          invalid for any reason, such invalidity will not affect the validity
          of the rest of these Terms. Failure to assert any right in regard to
          these Terms on our behalf shall not constitute concession, yield or
          relinquishment of any sort. You consent to receive electronic
          communications from us, including promotional material, and agree that
          email communication to the email address via which you communicated
          with us and/or email address associated with your social media account
          or direct messages therein, notices posted on the Service or messages
          displayed when you enter the Service, all satisfy any legal
          requirement that such communication be in writing. If you have any
          question, requests or comments regarding these Terms or the Service,
          please contact us at info@football-today.com .
        </p>
      </Main>
    </Stack>
  </Layout>
)

export default TermsOfUse
